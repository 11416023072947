import React from "react"
import { Helmet } from "react-helmet"

//import Layout from "../kdp_theme/Layout"
import KdpBanner from "../../../components/banner"
import KdpHeader from "../../../components/headers/header"
import KdpFooter from "../../../components/footers/footer"


export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Postulez pour devenir Plombier Partenaire blicko | Le plombier 3.0</title>
        <meta name="description" content="Candidature Plombier blicko | Retrouvez la démarche et le formulaire de contact pour postuler comme plombier chez blicko" />
      </Helmet>
      <header>
        <KdpBanner />
        <KdpHeader />
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                Merci de votre demande
                </h2>
                <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                Nous vous remercions pour votre candidature.
                </p>
                <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                Nous sommes ravis de vous compter bientôt parmi nos partenaires.
                </p>
                <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                N’hésitez pas à nous contacter par téléphone au <a href="tel:0974371440" className="text-blue-500">09 74 37 14 40</a>.
                </p>
            </div>
            </div>
        </div>
        <KdpFooter />
      </header>
      <main>

      </main>
    </div>
  );
}